<template>
    <div class="attachmentList">
        <!-- 附件列表 -->
        <div class="attachmentTop">
            <el-button type="primary" size="small" @click="toRouter('attachmentClassify')">分类管理</el-button>
            <el-button type="primary" size="small" @click="toRouter('attachmentUpload')">上传</el-button>
            <el-button type="primary" size="small" @click="deleteAttachment">删除</el-button>
        </div>
        <div class="searchBtn">
            <el-input placeholder="请输入" v-model="searchInfo.title" clearable size="small"></el-input>
            <el-select v-model="searchInfo.categoryId" placeholder="请选择" size="small">
                <el-option v-for="item in attachmentTypeList" :key="item.id" :label="item.title" :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="search">查询</el-button>
        </div>
        <div class="tableContent">
            <div class="item" v-for="item in attachmentList" :key="item.id" @click="choiceDelete(item)">
                <img :src="item.path" alt="">
                <span class="name">{{ item.title }}</span>
                <div class="select" v-show="item.select === true">
                    <span>
                        <i class="el-icon-check"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total,  prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { attachmentListAPI, deleteAttachmentAPI } from '@/api/attachment/attachment'
import { getAttachmentTypeListAPI } from '@/api/attachment/attachmentType'
export default {
    data() {
        return {
            attachmentTypeList: [], // 附件分类列表
            attachmentList: [], // 附件数据
            deleteList: [], //需要删除的数据
            searchInfo: {
                title: null,
                categoryId: 0,
            },
            // 分页
            paging: {
                p: 1,
                ps: 10,
                total: 0,
            },
        }
    },
    methods: {
        // 切换分页
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.paging.p = val;
            this.getList();
        },
        // 跳转到上传或分类管理
        toRouter(val) {
            this.$router.push({
                name: val
            })
        },
        // 选择需要删除的数据
        choiceDelete(val) {
            if (val.select === true) {
                val.select = false
            } else {
                this.attachmentList.filter(item => {
                    if (item.id === val.id) {
                        item.select = true
                    }
                })
            }

            this.$forceUpdate();
            // val.select= true;
            // console.log(val)
            // let arr = []
            // if(this.deleteList.length > 0) {
            //     this.deleteList = this.deleteList.forEach(item => {
            //     if(item === val) {
            //         // item.select = false;
            //         return item !== val;
            //     } 
            // });
            // } else {
            //     this.deleteList.push(val)
            // }
            // console.log(this.deleteList)

        },
        // 删除数据
        deleteAttachment() {
           // console.log(this.attachmentList);
            let arr = [];
            this.attachmentList.forEach(item => {
                if (item.select === true) {
                    arr.push(item.id);
                }
            })
            if (arr.length > 0) {
                this.$confirm('确认删除该数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    
                    const res = await deleteAttachmentAPI(JSON.stringify(arr));
                  //  console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.getList();
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败！' + res.msg,
                            type: 'error'
                        });
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择需要删除的数据',
                    type: 'info'
                });
            }
            // const res = await 
        },
        // 获取附件列表
        async getList() {
            let info = { pageSize: this.paging.ps, pageNum: this.paging.p, ...this.searchInfo }
            const res = await attachmentListAPI(info);
          //  console.log(res);
            if (res.code === 200) {
                this.attachmentList = res.data.list;
                this.attachmentList.forEach(item => {
                    item.select = false;
                    // item.img = this.$store.state.requestAPI + item.path
                })
                this.paging.total = res.data.total;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }

        },
        // 搜索
        search() {
            this.paging.p = 1;
            this.getList();
        },
        // 获取附件分类列表
        async getTypeList() {
            let info = { pageSize: 100000, pageNum: 1 }
            const res = await getAttachmentTypeListAPI(JSON.stringify(info));
           // console.log(res);
            if (res.code === 200) {
                this.attachmentTypeList = [{ id: 0, title: '全部' }, ...res.data.list];
                // this.paging.total = res.data.total;
            }
        },

    },
    created() {
        this.getList();
        this.getTypeList();
    }
}
</script>

<style lang="scss" scoped>
.attachmentList {
    .attachmentTop {
        width: 100%;
        text-align: right;
    }

    .tableContent {

        // max-height: 500px;
        .item {
            position: relative;
            margin: 5px;
            display: inline-block;
            width: 280px;
            height: 220px;
            border: 1px solid rgb(152, 151, 151);

            img {
                height: 100%;
                width: 100%;
            }

            .name {
                display: block;
                width: 100%;
                height: 30px;
                background-color: #eee;
                line-height: 30px;
                text-align: center;
                position: absolute;
                bottom: 0px;
            }

            .select {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                background: rgba(0, 0, 0, 0.1);
                z-index: 1;
                padding: 5px;
                box-sizing: border-box;

                span {
                    display: inline-block;
                    text-align: center;
                    // color: white;
                    height: 20px;
                    width: 20px;
                    background: white;

                    .el-icon-check {
                        // position: absolute;
                        // top: 0;
                        // right: 0;
                    }
                }

            }

        }
    }
}
</style>